import InvoiceInput from "./InvoiceInput";
import { useEffect, useState } from "react";
import GeneralService from "../../../Services/Dashboard/GeneralService";

const InvoiceNumber = ({ setInvoiceNumber, invoiceNumber }) => {
  ///doont uncomment this code the useeffect runs till infinity
  // const [state, setState] = useState(0);

  // useEffect(() => {
  //   async function fetchData() {
  //     const response = await GeneralService.invoiceNumber();
  //     if (response.success) {
  //       let id =
  //         parseInt(response?.data?.data[0]?.invoice_number ?? 0) + 1 ??
  //         "0000001";
  //         console.log("invoice id", id)
  //         console.log("response id", response?.data?.data[0])
  //       setState(id);
  //       setInvoiceNumber(id);
  //     }
  //   }

  //   fetchData();
  // });

  return (
    <>
      {/* ///i changed the invoice from normalize true */}
      <InvoiceInput
        type="text"
        placeholder="Invoice Number"
        name="invoice_number_format"
        normalize='true'
        options={{
          prefix: "#",
          rawValueTrimPrefix: true,
          numeralDecimalScale: 0,
          delimiter: "",
        }}
        onChange={(e) => setInvoiceNumber(e.target.value)}
        value={invoiceNumber}
      />
      <input type="hidden" name="invoice_number" defaultValue={invoiceNumber} readOnly/>
    </>
  );
};

export default InvoiceNumber;
